.employees-block-container {
  position: relative;
  background-color: $white-d1;
    div[class*="col-"]{
        margin-bottom: 0;
    }
  .employees-block {
    padding-bottom: 120px;
    @include media-breakpoint-down(md) {
      padding-bottom: 500px;
    }
    &.eight{
      .white-block{
        @include media-breakpoint-up(lg){
          min-height: 500px;
        }
      }
    }
    .grey-box {
      background-color: $white-d1;
      height: 160px;
      &:before {
        content: "";
        position: absolute;
        border-right: 100vw solid transparent;
        border-top: 100px solid white;
        @include media-breakpoint-down(sm) {
          border-top: 50px solid white;
        }
      }
    }
    .gradient {
      margin: 0 auto;
      width: calc(100vw - ((100vw - 200px) * 0.4) - 80px);
      background: linear-gradient(200deg, #32d964, #32d0d9, #e178b3);
      background-size: 600% 600%;
      -webkit-animation: AnimationName 10s ease infinite;
      -moz-animation: AnimationName 10s ease infinite;
      animation: AnimationName 10s ease infinite;
      @media (min-width: 1440px) {
        max-width: 1340px;
        width: calc(100vw - 580px);
      }
      @include media-breakpoint-down(lg) {
        max-width: calc(100vw - 80px);
        width: calc(100vw - (((100vw - 200px) * 0.4) - 320px) - 110px);
      }
      @include media-breakpoint-down(md) {
        width: 100%;
      }
      .row {
        margin: 0px -16px;
        .employee {
          padding: 0;
          border: 16px solid $white-d1;
          border-bottom: 90px solid $white-d1;
          position: relative;
          @include media-breakpoint-between(sm,md){
            flex: 0 0 33% !important;
            max-width: 33% !important;
            }
          img {
            min-width: 100%;
            max-width: 100%;
            height: auto;
            margin-top: 40px;
          }

          .employee-description {
            padding: 8px 0px 0px 8px;
            @include media-breakpoint-down(lg) {
              padding: 8px 0px 0px 4px;
            }
            position: absolute;
            h4 {
              font-size: 2rem;
              @include media-breakpoint-down(lg) {
                font-size: 1.6rem;
              }
              @include media-breakpoint-down(xs) {
                font-size: 2rem;
              }
            }
            p.description {
              font-size: 1.6rem;
              line-height: 1.6rem;
            }
          }
        }
        .white-block {
          background-color: $white-d1;
          @include media-breakpoint-down(md) {
            display: none;
          }
          @include media-breakpoint-between(sm,md){
            flex: 0 0 33% !important;
            max-width: 33% !important;
            }
          &.last-one {
            @include media-breakpoint-down(md) {
              display: block;
            }
            @include media-breakpoint-down(xs) {
              display: none;
            }
          }
        }
        .white-block-sm {
          background-color: $white-d1;
          @include media-breakpoint-up(md) {
            display: none;
          }
        }
      }
    }
  }
  .tobbi-container {
    // position: relative;
    // margin-left: auto;
    // margin-right: 100px;
    // margin-top: -160px;
    position: absolute;
    width: 50vw;
    height: 50vw;
    // height: 27vw;
    // width: 36vw;
    right: 0px;
    bottom: 0px;
    overflow: hidden;
    @media (min-width: 1440px) {
      max-width: 700px;
      max-height: 700px;
      right: calc((100vw - 1440px) / 2);
      overflow: visible;
    }
    @include media-breakpoint-down(lg) {
      bottom: 60px;
    }
    @include media-breakpoint-down(md) {
      height: 48vw;
      width: 60vw;
      min-height: 404px;
      bottom: 60px;
      z-index: 1;
    }
    @include media-breakpoint-down(xs) {
      width: 100%;
    }
    .pink {
      position: absolute;
      height: 28vw;
      width: 28vw;
      @include transparent-background($pink, 0.4);
      border-radius: 50%;
      right: -16%;
      bottom: 12%;
      min-width: 400px;
      min-height: 400px;
      @media (min-width: 1440px) {
        max-width: 404px;
        max-height: 404px;
      }
      @include media-breakpoint-down(lg) {
        min-width: 300px;
        min-height: 300px;
      }
      @media screen and (max-width: 450px) {
        right: -30%;
      }
      
    }
    .blue {
      position: absolute;
      height: 22vw;
      width: 22vw;
      @include transparent-background($blue-light, 0.4);
      border-radius: 50%;
      bottom: 40%;
      right: 18%;
      min-width: 300px;
      min-height: 300px;
      @media (min-width: 1440px) {
        max-width: 317px;
        max-height: 317px;
      }
      @include media-breakpoint-down(lg) {
        right: 24%;
        bottom: 44%;
        min-width: 225px;
        min-height: 225px;
      }
    }
    .yellow {
      position: absolute;
      height: 22vw;
      width: 22vw;
      @include transparent-background($yellow, 0.4);
      border-radius: 50%;
      bottom: 12%;
      right: 28%;
      min-width: 300px;
      min-height: 300px;
      @media (min-width: 1440px) {
        max-width: 317px;
        max-height: 317px;
      }
      @include media-breakpoint-down(lg) {
        right: 33%;
        min-width: 225px;
        min-height: 225px;
      }
      @include media-breakpoint-down(xs) {
        right: 34%;
      }
    }
    .tobbi-about {
      position: absolute;
      bottom: 0px;
      right: 24%;
      img {
        padding-bottom: 100px;
        width: 100%;
        @include media-breakpoint-down(lg) {
          width: 200px;
        }
        @include media-breakpoint-down(md) {
          width: 160px;
        }
      }
      .text {
        position: absolute;
        text-align: center;
        width: 100%;
        bottom: 0px;
      }
    }
  }
}

@-webkit-keyframes AnimationName {
  0% {
    background-position: 74% 0%;
  }
  50% {
    background-position: 27% 100%;
  }
  100% {
    background-position: 74% 0%;
  }
}
@-moz-keyframes AnimationName {
  0% {
    background-position: 74% 0%;
  }
  50% {
    background-position: 27% 100%;
  }
  100% {
    background-position: 74% 0%;
  }
}
@keyframes AnimationName {
  0% {
    background-position: 74% 0%;
  }
  50% {
    background-position: 27% 100%;
  }
  100% {
    background-position: 74% 0%;
  }
}
