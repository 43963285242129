.oclandingpage{
    padding-top: 20vh;
    background-color: $background-grey;
    @include media-breakpoint-down(sm){
        padding-top: 14vh;
    }
}
.page-oclandingpage{  
    .nav-container{
        max-width: unset !important;
        .nav{
            box-shadow: none;
            &.nav-down{
                background-color: transparent;
            }
            &.nav-up{
                background-color: transparent;
            }
            .name{
                svg{
                    width: 146px;
                    height: 27px;
                }
            }
            .navbar-toggler{
                .navbar-toggler-icon{
                    span{
                        background-color: $white;
                    }
                }
            }
        }
    }
    .top-section{
        .svg-col{
            margin-bottom: 0;
        }
        .text-col{
            @include media-breakpoint-down(sm){
                order: 2;
            }
        }
        .text-container{
            color: $white;
            .title{
                color: $white;
                margin-top: 20px;
                @include fluid-type(414px, 1920px, 28px, 62px);
            }
            .text{
                ul{
                    margin-top: 40px;
                    @include media-breakpoint-down(md){
                        padding-left: 26px;
                    }
                    li{
                        list-style-type: none;
                        position: relative;
                        @include fluid-type(414px, 1920px, 18px, 24px);
                        line-height: 24px;
                        margin-bottom: 15px;
                        color: $white;
                        font-weight: 500;
                        &::before{
                            content: '';
                            position: absolute;
                            width: 15px;
                            height: 1px;
                            background-color: $yellow;
                            top: 15px;
                            left: -37px;
                            @include media-breakpoint-down(md){
                                left: -26px;
                            }
                        }
                        i{
                            font-style: normal;
                            color: $yellow;
                        }
                    }
                }
            }
        }
        .svg-container{
            @include media-breakpoint-down(sm){
                text-align: right;
                svg{
                    max-width: 60vw !important;
                }
            }
            svg{
                max-width: 560px;
            }
        }
    }
    footer{
        @include media-breakpoint-down(sm){
            padding-top: 70px;
        }
    }
}