.project-page {
    position: relative;
    max-width: 500vw;
    overflow-x: hidden;
    padding: 60px 0px;

    @include media-breakpoint-down(md) {
        padding-top: 150px;
    }

    @include media-breakpoint-down(sm) {
        padding: 50px 0px;
    }

    .hero-block {
        margin-bottom: 60px;
        h3 {
            text-align: left !important;
        }

        .link {
            .rich-text {
                a {
                    font-size: 1.8rem;
                    border-bottom: 1px solid $yellow;
                    color: $grey-dark;
                    text-decoration: none;
                    padding-bottom: 8px;
                }
            }
        }
    }

    .header-image-container {
        max-height: 700px;
        overflow: hidden;
        margin: 60px 0px 50px 0px;

        @include media-breakpoint-down(sm) {
            margin: 50px 0px 40px 0px;
        }

        @include media-breakpoint-down(md) {
            max-height: 100%;
        }

        .header-image {
            min-height: 100%;
            min-width: 100%;
            max-width: unset;

            &.mobile-image {
                max-width: 100% !important;
            }
        }

        &:before {
            content: "";
            position: absolute;
            border-right: 100vw solid transparent;
            border-top: 100px solid white;
            left: 0;

            @include media-breakpoint-down(md) {
                border-top: 40px solid white;
            }

            @include media-breakpoint-down(sm) {
                display: none;
            }
        }
    }

    .block-container {
        .block {
            margin: 0 auto;
            max-width: 750px;

            @include media-breakpoint-down(sm) {
                width: calc(100% - 80px);
                margin: 0;
            }

            @include media-breakpoint-down(xs) {
                width: calc(100% - 32px);
            }
        }
    }

    .project-container {
        position: absolute;
        bottom: 100px;
        left: 8%;
        margin: 0px;

        @include media-breakpoint-down(lg) {
            left: 50%;
            transform: translateX(-50%);
            padding: 60px 60px 100px;
            bottom: 150px;
        }

        @include media-breakpoint-down(md) {
            left: 0px;
            max-width: 75vw;
            transform: translateX(0%);
        }

        @include media-breakpoint-down(sm) {
            bottom: 80px;
            max-width: 90vw;
            padding: 60px 60px 70px;
        }
    }

    .rich-text {

        h1,
        h2,
        h3 {
            padding-top: 30px;
            padding-bottom: 5px;
        }
    }

    .more-projects {
        padding-top: 100px;

        a {
            .img-overlay {
                    opacity: 0;
                    position: absolute;
                    z-index: 1;
                    top: 0;
                    left: 0;
                    right: 0;
                    bottom: 0;
                    background-color: rgba(0, 0, 0, 0.7);
                    overflow: hidden;
                    transition: opacity .6s ease-in-out;
                h4 {
                    display: none;
                    position: absolute;
                    left: 50%;
                    transform: translateX(-50%);
                    top: 50%;
                    white-space: nowrap;
                    color: $white;
                }
            }

            &:hover, &:focus {
                text-decoration: none;
                color: transparent;
                .img-overlay {
                    opacity: 1;
                    h4 {
                        display: block;   
                    }
                }
            }
        }

        .more-projects-btn {
            @include fluid-type(414px, 1920px, 18px, 22px);
            font-weight: 600;
            padding-bottom: 8px;
            border-bottom: 1px solid $yellow;
            color: #505050;
            white-space: nowrap;

            &:hover {
                text-decoration: none; 
                color: #505050;
            }
        }
        .images-slider {
            .img-overlay{
                h4 {
                    top: 44%;
                    font-size: 22px;

                }
            }
            .slick-list {
                .slick-track {
                    .project, .slide, .slick-slide  {
                        padding: 0;
                        
                    }
                }
            }
        }
    }

    .excerpt {
        position: relative;
        p {
            @include fluid-type(414px, 1920px, 20px, 22px);
            font-style: italic;
            border-left: 2px solid $yellow;
            border-radius: 1px;
            padding-bottom: 0px;
            margin-left: -20px;
            padding-left: 20px;
        }
    }
}
