.proudcts-block-container{
    background-color: $white-d1;
    position: relative;
    padding: 100px 0px 1px;
    &:not(.no-line)::before{
        content: "";
        position: absolute;
        border-left: 100vw solid transparent;
        border-top: 5vw solid $white;
        top: 0px;
        left: 0;
    }
    &.no-line{
        @include media-breakpoint-up(md){
            .hero-block{
                margin-top: 0;
                margin-bottom: 0;
            }
            .line-col{
                .line{
                    width: 0;
                }
            }
        }
        @include media-breakpoint-down(md){
            padding-top: 0;
        }
    }
    &.right{
        .products-block{
            margin-bottom: 180px;
            &:nth-child(odd){
                .block-col{
                    margin-left: 6.25% !important;
                }
            }
            &:nth-child(even){
                .block-col{
                    margin-left: 6.25% !important;
                }
                .block-container{
                    justify-content: flex-end;
                }
                .block-col{
                    order: -1;
                    @include media-breakpoint-down(md){
                        order: unset !important;
                    }
                }
                .image-col{
                    margin-left: -6.25%;
                }
            }
        }
    }
    .hero-block{
        margin-top: 200px;
        padding-bottom: 0 !important;
        @include media-breakpoint-down(md){
            margin-bottom: 30px;
            margin-top: 0;
            .text-col{
                margin-bottom: 0;
                h2{
                    font-size: 32px;
                    line-height: 36px;
                }
                p{
                    font-size: 18px;
                    line-height: 24px;
                }
            }
        }
        .line {
            width: 100%;
            background-color: $yellow;
            border-radius: 1px;
            margin-top: 16px;
            height: 2px;
            &::after{
                content: none !important;
            }
        }
    }
    h3 {
        font-weight: 600 !important;
        @include fluid-type(414px, 1920px, 28px, 36px);
        &.mobile-text-center {
            @include media-breakpoint-down(sm) {
                text-align: center;
            }
        }
        @include media-breakpoint-down(md){
            line-height: 30px;
        }
    }
    .description{
        text-align: center;
        padding-top: 100px;
        padding-bottom: 80px;
    }
    .products-block{
        margin-bottom: 180px;
        &:nth-child(even){
            .block-col{
                margin-left: -6.25% !important;
            }
            @include media-breakpoint-down(md){
                .image-col{
                    margin-left: 6.25%;
                }
            }
        }
        &:nth-child(odd){
            .block-container{
                justify-content: flex-end;
            }
            .image-col{
                margin-left: -6.25%;
            }
            .block-col{
                order: -1;
                @include media-breakpoint-down(md){
                    margin-left: 6.25%;
                    order: unset !important;
                }
            }
        }
        .image-col{
            margin-bottom: 0;
            @include media-breakpoint-down(md){
                padding: 0;
            }
            .overlay-container{
                position: relative;
            }
            .header-image-container{
                &:hover {
                    text-decoration: none;
                }
                .header-image{
                    min-width: 100%;
                    object-fit: cover;
                    @include media-breakpoint-down(sm){
                        min-height: 380px;
                    }
                }
                .overlay{
                    position: absolute;
                    height: 100%;
                    width: 100%;
                    top: 0px;
                    left: 0;
                    opacity: .85;
                    background-color: $pink;
                    &.airdate\.svg{
                        background: linear-gradient(135deg, $green 0%, $green-d2 100%);
                    }
                    &.askell\.svg{
                        background-color: $askell-light-blue;
                    }
                    &.annual_reports\.svg{
                        background: linear-gradient(135deg, #EFDC82 0%, #F4D027 100%);
                    }
                }
                .icon-container{
                    position: absolute;
                    width: 200px;
                    height: 200px;
                    border-radius: 50%;
                    background-color: $white;
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    justify-content: center;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                    @include media-breakpoint-down(md) {
                        height: 160px;
                        width: 160px;
                        img {
                            width: 50%;
                        }
                    }
                    @include media-breakpoint-down(sm){
                        top: 30px;
                        left: 50%;
                        transform: translateX(-50%);
                    }
                    @include media-breakpoint-down(xs){
                        height: 120px;
                        width: 120px;
                    }
                    img{
                        max-width: 105px;
                    }
                }
            }
        }
        .block-col{
            margin-bottom: 0;
            @include media-breakpoint-down(md){
                margin-left: unset;
                padding: 0;
                margin-top: -200px;
            }
            .description-container{
                background-color: $white;
                padding: 110px 60px;
                position: relative;
                -webkit-box-shadow: 0px 30px 70px 0px rgba(0, 0, 0, 0.15);
                -moz-box-shadow: 0px 30px 70px 0px rgba(0, 0, 0, 0.15);
                box-shadow: 0 25px 50px 0 rgba(0,0,0,0.15);
                @include media-breakpoint-down(md){
                    padding: 60px 40px;
                }
                h3{
                    @include media-breakpoint-down(sm){
                        margin-bottom: 12px;
                    }
                }
                .excerpt{
                    p{
                        font-size: 20px;
                        line-height: 30px;
                        @include media-breakpoint-down(md){
                            font-size: 18px;
                            line-height: 24px;
                        }
                    }
                }
                .block-container{
                    background-color: $white;
                    &:hover {
                        text-decoration: none;
                    }
                }
            }
            .see-more {
                background-color: $grey;
                color: $white;
                font-size: 1.8rem;
                position: absolute;
                width: 350px;
                height: 105px;
                display: flex;
                align-items: center;
                justify-content: center;
                bottom: -52.5px;
                left: 50%;
                transform: translateX(-50%);
                font-weight: 600;
                @include media-breakpoint-down(sm) {
                    width: 250px;
                    height: 75px;
                    bottom: -37.5px;
                    font-size: 1.4rem;
                }
            }
        }
    }
}


