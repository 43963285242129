.bullet-block-container{
    padding-top: 40px;
    padding-bottom: 40px;
    &:last-child{
        .bullet-block{
            min-height: 50vh !important;
        }
    }
}
.bullet-block{
    min-height: 98vh;
    margin: 0 auto;
    .line{
        border-bottom: 1px solid $grey-l1;
        &.after{
            &::after{
                content: '';
                position: absolute;
                top: 1px;
                height: 40px;
                z-index: 10;
                width: 20%;
                background-color: $background-grey;
            }
        }
    }
    .svg-container{
        @include media-breakpoint-down(sm){
            margin: 0;
            &.right{
                order: 0;
            }
        }
        img{
            margin: 50px 0;
        }
        &.coffee{
            flex: 0 0 12.5%;
            max-width: 12.5%;
            margin-right: 2%;
            @include media-breakpoint-down(md){
                flex: 0 0 100%;
                max-width: 100%;
                text-align: center;
                margin-right: 0;        
            }
        }
    }
    .text-container{
        @include media-breakpoint-down(sm){
            &.right{
                order: 2;
            }
        }
        h2{
            color: $white;
            margin-bottom: 20px;
            line-height: 40px;
            @include fluid-type(414px, 1920px, 36px, 52px);
        }
        .rich-text{
            p{
                color: $white !important;
                @include fluid-type(414px, 1920px, 17px, 20px);
                @include media-breakpoint-down(md){
                    line-height: 26px;
                }
            }
            a{
                color: $yellow-light;
            }
            ul{
                margin-top: 40px;
                padding-left: 30px;
                li{
                    list-style-type: none;
                    position: relative;
                    color: $white;
                    font-size: 22px;
                    line-height: 28px;
                    margin-bottom: 12px;
                    &:before{
                        content: '';
                        position: absolute;
                        width: 11px;
                        height: 1px;
                        background-color: $yellow;
                        left: -24px;
                        top: 16px;
                    }
                }
            }
        }
    }
}