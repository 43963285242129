

%subtext{
    font-size: 1.6rem;
    color: $grey-light;
}

@mixin subtext($color, $alignment) {
    font-size: 1.6rem;
    color: $color;
    text-align: $alignment;
}