.campaign-header-block{
    background-color: $grey_d2;
    margin-bottom: 120px;
    position: relative;
    z-index: 1;
    &:before{
        content: "";
        position: absolute;
        border-right: 100vw solid transparent;
        border-top: 100px solid white;
        left: 0;
        @include media-breakpoint-down(md) {
            border-top: 40px solid white;
        }
    }
    .block-container{
        position: relative;
        padding-bottom: 110px;
        padding-top: 160px;
        &.large {
            overflow: hidden;
            height: 100%;
            img.header-image {
                min-width: 100%;
                min-height: 100%;
                height: auto;
                width: auto;
                max-width: unset;
            }
        }
        .text-col{
            margin-top: 140px;
            @include media-breakpoint-down(sm){
                order: 1;
                position: relative;
            }
            .block {
                display: block;
                max-width: 750px;
                @include media-breakpoint-down(sm){
                    padding: 0;
                }
                &:hover {
                    text-decoration: none;
                }
                div, h1, p{
                    color: $white;
                }
                .rich-text{
                    ul{
                        margin-top: 40px;
                        @include media-breakpoint-down(md){
                            padding-left: 26px;
                        }
                        li{
                            list-style-type: none;
                            position: relative;
                            @include fluid-type(414px, 1920px, 20px, 30px);
                            line-height: calc(40px + (65 - 40) * ((100vw - 414px) / (1920 - 414)));
                            color: $white;
                            font-weight: 500;
                            &::before{
                                content: '';
                                position: absolute;
                                width: 15px;
                                height: 1px;
                                background-color: $yellow;
                                top: calc(15px + (33 - 15) * ((100vw - 414px) / (1920 - 414)));
                                left: -37px;
                                @include media-breakpoint-down(md){
                                    left: -26px;
                                    top: 20px;
                                }
                            }
                            i{
                                font-style: normal;
                                color: $yellow;
                            }
                        }
                    }
                }
                h1.campaign-headline{
                    @include fluid-type(414px, 1920px, 30px, 60px);
                    line-height: calc(36px + (65 - 36) * ((100vw - 414px) / (1920 - 414)));
                    font-weight: 900;
                    svg{
                        height: 40px;
                        margin: 0 0 8px 0;
                        @include media-breakpoint-down(md){
                            height: 24px;
                        }
                    }
                }
            }
        }
        .svg-col{
            min-height: 400px;
            @include media-breakpoint-down(sm){
                position: relative;
                order: 0;
                min-height: unset;
                .svg-container{
                    position: absolute;
                    bottom: 0;
                    max-width: unset !important;
                    @include media-breakpoint-down(md){
                        position: relative;
                        bottom: unset;
                    }
                    img{
                        max-height: 270px;
                    }
                }
            }
            .svg-container{
                max-width: 570px;
                svg{
                    width: 100%;
                    .one{
                        animation-duration: 9s;
                        animation-name: zoomIn;
                        animation: rotate 2s linear infinite;
                    }
                    .two{
                        animation-duration: 6s;
                        animation-name: zoomIn;
                    }
                    .three{
                        animation-duration: 7s;
                        animation-name: zoomIn;
                    }
                    .six{
                        animation-duration: 5s;
                        animation-name: zoomIn;
                    }
                }
                // @keyframes zoomIn {
                //     from {
                //         transform: scale(0);
                //     }
                //     to {
                //       transform: scaleX(1);
                //     }
                // }
                // @keyframes rotate {
                //     from {
                //         transform: translate(-10px);
                //     }
                //     to {
                //       transform: translate(10px);
                //     }
                // }
            }
        }
        .button-row{
            @include media-breakpoint-down(md){
                margin-top: 30px;
            }
        }
        a{
            text-decoration: none;
            .see-more{
                &.main{
                    &:before{
                        content: "";
                        width: 30px;
                        height: 2px;
                        background-color: $yellow;
                        margin-left: -50px;
                        margin-right: 20px;
                        @include media-breakpoint-down(md) {
                            width: 20px;
                            margin-left: 0px;
                            margin-right: 16px;
                        }
                    }
                }
                &.plus{
                    background-color: $yellow;
                    border: none;
                    width: 100%;
                    max-width: 257px;
                    height: 76px;
                    border-radius: 38px;
                    font-size: 24px;
                    line-height: 36px;
                    color: $grey_d2;
                    font-weight: 500;
                    @include media-breakpoint-down(md){
                        width: 190px;
                        height: 50px;
                        font-size: 20px;
                    }
                    &:focus {
                        box-shadow: none;
                    }
                    &:after {
                        content: "";
                        height: 0%;
                        width: 0%;
                        border-radius: 50%;
                        background-color: $grey_d2;
                        top: 50%;
                        left: 50%;
                        transform: translate(-50%, -50%);
                        position: absolute;
                        transition: all .1s ease-out;
                    }
                }
            }
        }
    }
}