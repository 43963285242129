.product-page{
    padding-top: 60px;
    padding-bottom: 100px;
    @include media-breakpoint-down(lg){
        padding-top: 120px;
    }
    h2 {
        @include fluid-type(414px, 1920px, 30px, 40px);
        line-height: calc(34px + (46 - 34) * ((100vw - 414px) / (1920 - 414)));
        font-weight: 700 !important;
    }
    .line{
        position: absolute;
        top: 65px;
        margin-top: 0;
        z-index: -1;
        background-color: transparent;
    }
    .header-image-container{
        position: relative;
        overflow: hidden;
        width: 100%;
        max-height: 900px;
        min-width: 100vw;
        @include media-breakpoint-up(xl) {
            top: 40px;
        }
        @include media-breakpoint-down(lg){
            overflow: unset;
        }
        &:before{
            content: "";
            position: absolute;
            border-right: 100vw solid transparent;
            border-top: 5vw solid $white;
            z-index: 1;
            left: 0;
        }
        img {
            min-width: 100%;
            max-width: unset;
            height: auto;
            object-fit: cover;
            @include media-breakpoint-down(md){
                max-height: 400px;
                min-height: 360px;
                z-index: 0;
            }
        }
    }
    .intro-block{
        z-index: 1;
        position: relative;
        color: $white;
        margin-bottom: 200px;
        margin-top: calc(-100px + (-260 - -100) * ((100vw - 1024px) / (2460 - 1024)));
        @include media-breakpoint-down(lg){
            margin-top: -100px;
        }
        @include media-breakpoint-down(md){
            margin-top: 0;
            background-color: $white;
            box-shadow: 0 25px 100px 0 rgba(0,0,0,0.15);
        }
        @include media-breakpoint-down(sm){
            margin-bottom: 100px;
        }
        &.airdate\.svg{
            .intro-block-col{
                &:after {
                    @include transparent-background($green, 0.95);
                    @include media-breakpoint-down(md){
                        opacity: 0.95;
                        background: linear-gradient(135deg, #50E3C2 0%, #4BAC97 100%);
                    }
                }
            }
        }
        &.airserve\.svg{
            .intro-block-col{
                &:after {
                    @include transparent-background($pink, 0.95);
                    @include media-breakpoint-down(md){
                        opacity: 0.95;
                        background: linear-gradient(135deg, #E3506F 0%, #B73966 100%);
                    }
                }
            }
        }
        &.askell\.svg{
            .intro-block-col{
                &:after {
                    @include transparent-background($askell-light-blue, 0.95);
                }
                img{
                    max-width: 140px;
                }
            }
        }
        &.annual_reports\.svg{
            .intro-block-col{
                &:after {
                    @include transparent-background($yellow-d1, 0.95);
                }
                img{
                    max-width: 140px;
                }
            }
        }
        .intro-block-col{
            position: relative;
            &:after {
                content: "";
                position: absolute;
                z-index: -1;
                top: -38px;
                left: 34px;
                width: calc(100% - 17px);
                height: calc(100% + 68px);
                @include media-breakpoint-down(md){
                    height: 55px;
                    top: -55px;
                    left: unset;
                    right: -50%;
                    width: 150%;
                }
            }
        }
        .intro-text-block{
            background: $white;
            color: $grey;
            -webkit-box-shadow: 0px 30px 70px 0px rgba(0, 0, 0, 0.15);
            -moz-box-shadow: 0px 30px 70px 0px rgba(0, 0, 0, 0.15);
            box-shadow: 0px 30px 70px 0px rgba(0, 0, 0, 0.15);
            padding: 70px 68px;
            align-items: center;
            @include media-breakpoint-down(md) {
                box-shadow: none;
                flex-direction: column;
                padding: 0;
            }
            .text{
                padding-right: 64px;
                position: relative;
                max-width: 800px;
                @include media-breakpoint-down(md){
                    padding-right: 0;
                }
                .excerpt{
                    margin-top: 12px;
                    p{
                        line-height: calc(28px + (34 - 28) * ((100vw - 414px) / (1920 - 414))) !important;
                        font-size: calc(20px + (24 - 20) * ((100vw - 414px) / (1920 - 414))) !important;
                        @media (min-width: 1920px){
                            font-size: 24px !important;
                        }
                        @media (max-width: 768px){
                            font-size: 18px;
                        }
                    }
                }
            }
            .seperator{
                border-left: $grey-l2 solid 1px;
                height: 100%;
                min-height: 300px;
            }
            .icon-container{
                z-index: 2;
                align-self: flex-start;
                margin-top: 10px;
                @include media-breakpoint-down(md){
                    align-self: center;
                    display: flex;
                    justify-content: center;
                    margin-bottom: 36px !important;
                    margin-top: 50px;
                }
                h3{
                    @include media-breakpoint-down(sm){
                        font-size: 38px;
                    }
                }
                img {
                    width: 100%;
                    max-height: 147px;
                    @include media-breakpoint-down(md) {
                        width: 100%;
                    }
                }
            }
        }
    }
    .btn-container{
        a{
            width: 316px;
            height: 70px;
            border-radius: 35px;
            color: $white;
            font-weight: 500;
            font-size: 24px;
            &:hover{
                text-decoration: none;
                transition: box-shadow .2s linear
            }
            &.airdate\.svg{
                display: none;
                // background-color: $green-d1;
                // &:hover{
                //     background-color: darken($green-d1, 10%);
                //     transition: background-color .2s linear
                // }
            }
            &.askell\.svg{
                background-color: $purple;
                &:hover{
                    background-color: darken($purple, 10%);
                    transition: background-color .2s linear
                }
            }
            &.airserve\.svg{
                background-color: $pink;
                &:hover{
                    background-color: darken($pink, 10%);
                    transition: background-color .2s linear
                }
            }
        }
    }
}

