
.project-page-block, .blog-page-block{
    margin-bottom: 340px;
    @include media-breakpoint-down(md){
        margin-bottom: 240px;
    }
    .gray-bg{
        min-height: 500px;
        background-color: $white-d1;
        position: absolute;
        height: 89%;
        left: 47%;
        transform: translateX(-50%);
        bottom: -34px;
        min-width: calc(81.25% + 34px);
        &.bg-right{
            right: 47%;
            left: unset;
            transform: translateX(50%);
        }
    }
    &.main-project{
        position: relative;
        .outer-col{
            overflow: hidden;
            padding: 0;        
        }
        .header-image-container{
            &.large {
                picture{
                    width: 100%;
                    img.header-image {
                        min-width: 100%;
                        min-height: 100%;
                        height: auto;
                        width: auto;
                        max-width: unset;
                    }
                }
            }
            &:before{
                content: "";
                position: absolute;
                border-right: 100vw solid transparent;
                border-top: 100px solid $white;
                left: 0;
                @include media-breakpoint-down(md) {
                    border-top: 40px solid $white;
                }
            }
        }
        .block-container{
            position: absolute;
            bottom: -100px;
            max-width: 100vw;
            @include media-breakpoint-down(sm){
                .block-col{
                    padding: 0;
                }
            }
            .description-container{
                background-color: $white;
                padding: 96px 85px;
                position: relative;
                -webkit-box-shadow: 0px 30px 70px 0px rgba(0, 0, 0, 0.15);
                -moz-box-shadow: 0px 30px 70px 0px rgba(0, 0, 0, 0.15);
                box-shadow: 0 25px 50px 0 rgba(0,0,0,0.15);
                @include media-breakpoint-down(lg){
                    padding: 60px 40px;
                    .short-description{
                        margin-bottom: 26px !important;
                    }
                }
                @include media-breakpoint-down(sm){
                    padding: 30px 30px 48px;
                    .block{
                        h3{
                            margin-bottom: 12px;
                        }
                    }
                }
                .block {
                    display: block;
                    color: $grey-dark;
                    box-shadow: none;
                    &:hover {
                        text-decoration: none;
                    }
                    @include media-breakpoint-down(md) {
                        margin: 0px auto;
                    }
                    .short-description{
                        font-size: 1.4rem;
                        color: $grey;
                        letter-spacing: 2.57px;
                        text-transform: uppercase;
                        font-weight: 400;
                        margin-bottom: 60px;
                    }
                    h1{
                        font-size: 32px;
                        line-height: 41px;
                        @include media-breakpoint-down(sm){
                            font-size: 30px;
                            line-height: 30px;
                        }
                    }
                }
            }
        }
    }
    &.right{
        .block-container{
            &::after{
                left: unset !important;
                right: 0 !important;
            }
            .block-col{
                z-index: 1;
            }
            .image-col{
                order: 2;
                margin-left: -6.25%;
            }
            @include media-breakpoint-down(md){
                .image-col{
                    order: unset !important;
                    margin-left: unset !important;
                }
            }
        }
    }
    &.regular {
        position: relative;
        .block-container{
            .block-col{
                margin-bottom: 0;
                @include media-breakpoint-down(md){
                    margin-left: unset;
                    padding: 0;
                }
                &:not(.right-align){
                    margin-left: -6.25%;
                    @include media-breakpoint-down(md){
                        margin-left: unset;
                    }
                }
                &.right-align{
                    @include media-breakpoint-down(md){
                        margin-left: auto;
                    }
                }
            }
            .image-col{
                @include media-breakpoint-down(md){
                    padding: 0;
                    margin-bottom: -34px;
                }
                .header-image-container {
                    width: 100%;
                    height: 100%;
                    overflow: hidden;
                
                    .inner-img-container{
                        position: relative;
                        img {
                            height: 100%;
                            width: 100%;
                            max-height: 595px;
                            object-fit: cover;
                            @include media-breakpoint-down(md){
                                max-height: unset;
                            }
                        }
                    }
                }
            }
            .block {
                color: $grey-dark;
                align-self: center;
                &:hover {
                    text-decoration: none;
                }
            }
            .description-container{
                background-color: $white;
                padding: 90px 86px;
                position: relative;
                -webkit-box-shadow: 0px 30px 70px 0px rgba(0, 0, 0, 0.15);
                -moz-box-shadow: 0px 30px 70px 0px rgba(0, 0, 0, 0.15);
                box-shadow: 0 25px 50px 0 rgba(0,0,0,0.15);
                @include media-breakpoint-down(lg){
                    padding: 60px 40px;
                    .short-description{
                        margin-bottom: 26px !important;
                    }
                }
                @include media-breakpoint-down(sm){
                    padding: 30px 30px 48px;
                    .block{
                        h3{
                            margin-bottom: 12px;
                        }
                    }
                }
                .short-description{
                    font-size: 1.4rem;
                    color: $grey;
                    letter-spacing: 2px;
                    text-transform: uppercase;
                    font-weight: 500;
                    margin-bottom: 40px;
                }
            }
        }
    }
    .block-container {
        margin-top: 30px;
        .block {
            background-color: $white;
            -webkit-box-shadow: 0px 30px 70px 0px rgba(0, 0, 0, 0.15);
            -moz-box-shadow: 0px 30px 70px 0px rgba(0, 0, 0, 0.15);
            box-shadow: 0 25px 100px 0 rgba(0,0,0,0.15);
            .see-more{
                background-color: $grey;
                color: $white;
                font-size: 1.8rem;
                position: absolute;
                width: 326px;
                height: 90px;
                display: flex;
                align-items: center;
                justify-content: center;
                bottom: -52.5px;
                left: 50%;
                transform: translateX(-50%);
                font-weight: 600;
                @include media-breakpoint-down(sm) {
                    width: 250px;
                    height: 75px;
                    bottom: -37.5px;
                    font-size: 1.4rem;
                }
                &.small{
                    width: 96px;
                    height: 96px;
                }
                &.main{
                    &:before{
                        content: "";
                        width: 30px;
                        height: 2px;
                        background-color: $yellow;
                        margin-left: -50px;
                        margin-right: 20px;
                        @include media-breakpoint-down(sm) {
                            width: 20px;
                            margin-left: 0px;
                            margin-right: 16px;
                        }
                    }
                    &:hover{
                        color: $yellow;
                        transition: color .2s linear;
                        &::before{
                            background-color: $white;
                            transition: background-color .2s linear;
                        }
                    }
                }
            }
        }
    }
    .more-projects-btn{
        font-size: 24px;
        font-weight: 600;
        padding-bottom: 8px;
        border-bottom: 1px solid $yellow;
        margin: 60px auto;
        color: #505050;
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        bottom: -260px;
        white-space: nowrap;
        &:hover{
            text-decoration: none;
        }
    }
}
.button-container{
    display: none;
    &.last{
        display: block !important;
    }
}


