.nav-container{
    z-index: 10;
    position: fixed;
    .nav{
        height: 120px;
        position: relative;
        width: 100%;
        z-index: 1;
        @include media-breakpoint-down(sm) {
            transform: translateY(0);
            box-shadow: 0px 10px 40px rgba(0, 0, 0, 0);
            transition: transform .3s, box-shadow .3s;
            position: fixed;
            height: 120px;
        }
        div[class*="col-"]{
            margin-bottom: 0 !important;
        }
        &.nav-up {
            transform: translateY(-100%);
            transition: transform .3s;
        }
        &.menu-shadow {
            box-shadow: 0px 10px 40px rgba(0, 0, 0, 0.13);
            background-color: $white;
        }
        .logo {
            z-index: 1000;
            img.text{
                height: 100%;
                max-width: 156px;
            }
        }    
        .z-index-restore {
            z-index: 0;
        }            
        button.navbar-toggler{
            float: right;
            z-index: 2;
            position: relative;
            &:focus {
                outline: 0;
            }
            span.navbar-toggler-icon{
                width: 30px;
                height: 12px;
                cursor: pointer;
                position: relative;
                &.background--dark {
                    span {
                        background: $white;
                    }
                }
                @include media-breakpoint-down(sm) {
                    transform: scale(0.8);
                }
                span {
                    background: $grey-dark;
                    display: block;
                    position: absolute;
                    height: 2px;
                    width: 100%;
                    border-radius: 9px;
                    opacity: 1;
                    transition: .10s ease-in-out;
                    right: 0px;
                    &:nth-child(1){
                        top: 0px;
                    }
                    
                    &:nth-child(2) {
                        top: 10px;
                    }
                }
                &.open:hover span:first-of-type,
                &.open:hover span:last-of-type{
                    width: 32px;
                }
                &.open {
                    span {
                        background-color: $grey-d2 !important;
                        &:first-of-type {
                            top: 50%!important;
                            transform: translateY(-50%) rotate(45deg);
                        }
                        &:last-of-type {
                            top: 50%!important;
                            transform: translateY(-50%) rotate(-45deg);
                        }
                    }
                }
                &:hover {
                    @include media-breakpoint-up(xs) {
                        span:first-of-type {
                            width: 36px;
                        }
                        
                        span:last-of-type {
                            width: 24px;
                        }
                    }
                }
            }
        } 
    
        span.nav-oval{
            overflow: hidden;
            position: fixed;
            background-color: $yellow;
            border-radius: 50%;
            right: 55px;
            top: 50px;
            width: 0px;
            height: 0px;
            transition: all .5s ease-out;
            @media (min-width: 1440px) {
                right: calc((100vw - 1340px) / 2);
            }
            @include media-breakpoint-down(sm) {
                right: 40px;
                top: 40px;
            }
            .nav-items{
                opacity: 0;
                ul.menuitems{
                    width: 100vw;
                    list-style-type: none;
                    margin: 0px;
                    display: flex;
                    flex-direction: row;
                    flex-wrap: nowrap;
                    justify-content: center;
                    @include media-breakpoint-down(md) {
                        flex-direction: column;
                        padding-left: 80px;
                    }
                    @include media-breakpoint-down(sm) {
                        padding-left: 54px;
                    }
                    &.submenu-show {
                        li {
                            a, span {
                                color: $white;
                            }
                        }
                    }
                    li{
                        padding: 0px 20px;
                        display: flex;
                        &::before {
                            content: none;
                        }
                        @include media-breakpoint-down(md) {
                            padding: 20px 0px;
                            flex-direction: column;
                        }
                        @include media-breakpoint-down(xs) {
                            padding: 16px 0px;
                        }
                        a, span{
                            font-size: 3.6rem;
                            font-weight: 600;
                            color: $grey-dark;
                            @include media-breakpoint-up(md) {
                                &:hover{
                                    @include transparent-color($grey-dark, 0.6);
                                }
                            }
    
                            @include media-breakpoint-down(lg) {
                                font-size: 3rem;
                            }
                            @include media-breakpoint-down(sm) {
                                font-size: 2.6rem;
                            }
                            &:hover{
                                text-decoration: none;
                                cursor: pointer;
                            }
                            transition: .2s color 0s ease-in-out;
                        }
                        span {
                            padding-right: 0px;
                            transition: .2s padding-right 0s ease-in-out, .2s color 0s ease-in-out;
                            &:active {
                                opacity: 1;
                            }
                        }
                        .sub-menuitems {
                            list-style: none;
                            padding: 0px;
                            display: flex;
                            width: 0;
                            overflow: hidden;
                            margin: 0px;
                            position: relative;
                            transition: .2s width 0s ease-in-out;
                            @include media-breakpoint-down(md) {
                                width: auto;
                                height: 0px;
                                flex-direction: column;
                                transition: .2s height 0s ease-in-out;
                            }
                            &:after {
                                content: "";
                                position: absolute;
                                left: 0px;
                                top: 0px;
                                height: 100%;
                                width: 0px;
                                background-color: $grey-dark;
                                opacity: 0;
                                transition: .2s opacity 0s ease-in-out;
                                @include media-breakpoint-down(md) {
                                    content: none;
                                }
                            }
                            a {
                                opacity: 0;
                                font-size: 2.5rem;
                                font-weight: 500;
                                line-height: 6rem;
                                width: 0px;
                                transition: 0s opacity 0s ease-in-out;
                                position: relative;
                                @include media-breakpoint-down(lg) {
                                    line-height: 4.8rem;
                                    font-size: 2rem;
                                }
                                @include media-breakpoint-down(md) {
                                    line-height: 3.6rem;
                                }
                                @include media-breakpoint-down(md) {
                                    padding: 0px 24px;
                                    &:after {
                                        content: "";
                                        position: absolute;
                                        top: 50%;
                                        left: 0px;
                                        transform: translateY(-50%);
                                        height: 1px;
                                        width: 12px;
                                        background-color: $grey-dark;
                                    }
                                }
                                &:last-of-type() {
                                    padding-right: 0px;
                                }
                            }
                        }
                        &.show {
                            span {
                                padding-right: 20px;
                                transition: .2s padding-right 0s ease-in-out, .5s color .2s ease-in-out;
                                color: $grey-dark;
                            }
                            .sub-menuitems {
                                width: auto;
                                transition: .2s width 0s ease-in-out;
                                @include media-breakpoint-down(md) {
                                    min-height: 72px;
                                    height: auto;
                                    transition: .2s height 0s ease-in-out;
                                }
                                &:after {
                                    width: 1px;
                                    opacity: 1;
                                    transition: .5s opacity .2s ease-in-out;
                                }
                                a {
                                    padding: 0px 20px;
                                    width: auto;
                                    opacity: 1;
                                    transition: .5s opacity .2s ease-in-out;
                                    color: $grey-dark;
                                    &:hover {
                                        @include transparent-color($grey-dark, 0.6);
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    
    .active{
        max-height: 100vh;
        z-index: 50;
        span.nav-oval{
            position: fixed;
            background-color: $yellow;
            width: 200vw;
            height: 200vw;
            right: -50vw;
            top: -50vw;
            transition: all .5s ease-in;
            @include media-breakpoint-down(md) {
                width: 200vh;
                height: 200vh;
                right: -50vh;
                top: -50vh;
            }
            .nav-items{
                top: 50vh;
                transform: translateY(-50%);
                right: 0vw;
                opacity: 1;
                position: fixed;
                transition: opacity .3s ease-in .5s;
            }
        }
    }
}

@include media-breakpoint-down(md){
    .nav-container{
        background-color: $white;
    }
}