body.page-productpage{
  .gradient-bg{
    opacity: 0.17;
    background: radial-gradient(circle, $purple 0%, rgba(255,255,255,0) 100%);
    position: absolute;
    top: 0;
    left: 0;
    height: 70vw;
    width: 70vw;
  }
}
.product-page {
  overflow-x: hidden;
  @include media-breakpoint-down(lg) {
    padding-bottom: 120px;
  }
  .product-information-block {
    margin-top: 120px;
    margin-bottom: 400px;
    position: relative;
    @include media-breakpoint-between(sm, md){
      margin-bottom: 300px;
    }
    @include media-breakpoint-down(sm){
      margin-bottom: 460px;
    }
    .side-image-container {
      @include media-breakpoint-down(md){
        margin-left: 36%;
      }
      .screen-image-container {
        position: relative;
      }
      .header-image {
        object-fit: cover;
        height: auto;
        width: 100%;
      }
    }
    .block-container {
      position: absolute;
      z-index: 2;
      top: 45%;
      left: 20%;
      @media (max-width: 1300px){
        top: 35%;
      }
      @include media-breakpoint-down(md) {
        top: 50%;
        z-index: -1;
      }
      @include media-breakpoint-down(md){
        left: 6%;
      }
      .body {
        background-color: $white;
        padding: 120px 86px;
        -webkit-box-shadow: 0px 30px 70px 0px rgba(0, 0, 0, 0.15);
        -moz-box-shadow: 0px 30px 70px 0px rgba(0, 0, 0, 0.15);
        box-shadow: 0px 30px 70px 0px rgba(0, 0, 0, 0.15);
        position: relative;
        &::before{
          content: '';
          position: absolute;
          height: calc(100% - 70px);
          top: 41px;
          left: -34px;
          width: 34px;
        }
        @include media-breakpoint-down(md){
          padding-top: calc(120px + (220 - 120) * ((100vw - 414px) / (768 - 414))) !important;
        }
        &.airdate\.svg{
          &::before{
            background-color: $green-d1;
          }
        }
        &.askell\.svg{
          &::before{
            background-color: $purple;
          }
        }
        &.airserve\.svg{
          &::before{
            background-color: $pink;
          }
        }
        &.annual_reports\.svg{
          &::before{
            background-color: $yellow-d1;
          }
        }
        @include media-breakpoint-down(md){
          padding: 40px 48px 40px 48px;
          &::before{
            top: -20px;
            left: 34px;
            width: calc(100% - 34px);
            height: 20px;
          }
        }
        @include media-breakpoint-down(sm){
          padding: 40px 25px 40px 25px;
        }
        h2{
          font-weight: 700 !important;
        }
        p{
          font-size: calc(18px + (22 - 18) * ((100vw - 414px) / (1920 - 414))) !important;
          margin-top: 8px;
          line-height: calc(23px + (32 - 23) * ((100vw - 414px) / (1920 - 414)));
          padding-bottom: 0;
        }
      }
    }
  }
}
