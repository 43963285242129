$background-color: #FFFFFF;
$background-grey: #303030;

$askell-light-blue: #84BFD0;
$askell-blue: #1971A0;
$blue: #4286F5;
$blue-light: #499AD2;
$pink: #E0526E;
$green: #50E3C2;
$green-d1: #53AD99;
$green-d2: #4BAC97;
$yellow-d1: #F8D11C;
$yellow: #F8D11B;
$yellow-light: #FBCA45;
$purple: #b88cdc;
$purple_d1: #A058DA;

$white: #FFFFFF;
$white-d1: #F4F4F4;
$grey-l2: #d4d4d4;
$grey-l1: #979797;
$grey-light: #666666;
$grey: #4A4A4A;
$grey-dark: #353535;
$grey-d2: #2A2A2A;
$grey-d3: #525252;
$grey-d4: #202020;


$grey-transparent: rgba($grey, 0.4);

@mixin transparent-color($color, $transparency) {
    color: rgba($color, $transparency);
}
@mixin transparent-background($background, $transparency) {
    background-color: rgba($background, $transparency);
}