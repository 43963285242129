html {
  font-size: 62.5%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
body {
  font-size: 2rem;
  color: $grey;
  font-family: "futura-pt" !important;
  background-image: linear-gradient(to top, transparent 444px, #fff 0px);
  &.overflow {
    overflow-y: hidden;
    overflow-x: hidden;
    .line {
      z-index: -1;
    }
  }
  .content {
    background-color: $white;
  }
  &.airserve {
    overflow-x: hidden;
  }
}
.wagtail-userbar {
  right: 8em !important;
}
img {
  max-width: 100%;
}

main {
  padding-top: 40vh;
  
  @include media-breakpoint-down(md) {
    padding-top: 45vh;
  }

  &.blogpage, &.blogindexpage {
    padding-top: 30vh;
  }

  &.projectpage {
    padding-top: 0;
  }

  &.productpage {
    padding-top: 0;
    h1 {
      color: $grey-dark;
      font-weight: 700;
      @include fluid-type(414px, 1920px, 30px, 48px);
      @media (min-width: 1920px) {
        font-size: 48px;
      }
      @media (max-width: 414px) {
        font-size: 30px;
      }
    }
  }
  &.standardocpage {
    .standard-page {
      &.eight {
        position: relative;
      }
    }
  }
  &.airserve-main {
    padding-top: 0;
    &.standardaspage {
      margin-top: 50px;
    }
    &.airservelandingpage {
      margin-top: -110px;
      overflow-x: hidden;
      @include media-breakpoint-down(md) {
        margin-top: 30px;
      }
      p {
        @include fluid-type(414px, 1920px, 18px, 20px);
        line-height: calc(22px + (28 - 22) * ((100vw - 414px) / (1920 - 414)));
        color: $grey-d3 !important;
        margin: 10px 0;
      }
    }
  }
}

.btn.plus {
  max-width: 96px;
  max-height: 96px;
  width: 96px !important;
  height: 96px !important;
  bottom: -48px;
  border-radius: 50%;
  background-color: $grey;
  border: none;
  &:hover {
    &:after {
      height: 102%;
      width: 102%;
      transition: all 0.2s ease-out;
    }
    span.line {
      background-color: $white;
    }
  }
  &:focus {
    box-shadow: none;
  }
  @include media-breakpoint-down(sm) {
    width: 72px !important;
    height: 72px !important;
    bottom: -40px;
  }
  span.line {
    height: 2px;
    width: 24px;
    background-color: $white;
    z-index: 2;
    &:first-child {
      transform: rotate(90deg) translate(-10px, -12px);
    }
    &:last-child {
      transform: translate(-12px, -9px);
    }
  }
  &:after {
    content: "";
    height: 0%;
    width: 0%;
    border-radius: 50%;
    background-color: $yellow;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    position: absolute;
    transition: all 0.2s ease-out;
  }
}
h1 {
  color: $grey-dark;
  font-weight: 700;
  margin-bottom: 20px;
  @include fluid-type(414px, 1920px, 28px, 68px);
}

h2 {
  color: $grey-dark;
  font-weight: 600;
  @include fluid-type(414px, 1920px, 26px, 40px);
  line-height: calc(28px + (46 - 28) * ((100vw - 414px) / (1920 - 414)));
}

h3 {
  color: $grey-dark;
  font-weight: 600;
  @include fluid-type(414px, 1920px, 24px, 30px);
  line-height: calc(28px + (42 - 28) * ((100vw - 414px) / (1920 - 414)));
}
h4 {
  color: $grey-dark;
  font-weight: 600;
  @include fluid-type(414px, 1920px, 22px, 24px);
  line-height: calc(26px + (28 - 26) * ((100vw - 414px) / (1920 - 414)));
  @media (max-width: 768px) {
    font-size: 24px;
  }
}

blockquote {
  font-size: 2vmax;
  line-height: 2.5vmax;
  color: $grey-dark;
  font-weight: 500;
  font-style: oblique;
  @include media-breakpoint-down(md) {
    font-size: 2.4rem;
    line-height: 2.8rem;
  }
  @include media-breakpoint-down(sm) {
    font-size: 2rem;
    line-height: 2.4rem;
  }
}
.rich-text {
  p {
    @include fluid-type(414px, 1920px, 18px, 20px);
    line-height: calc(24px + (30 - 24) * ((100vw - 414px) / (1920 - 414)));
    color: $grey !important;
    padding-bottom: 18px;
  }
  a {
    @include fluid-type(414px, 1920px, 14px, 22px);
    line-height: calc(28px + (32 - 28) * ((100vw - 414px) / (1920 - 414)));
    color: $grey-light;
  }
}

.rich-text {
  ul {
    li {
      @include fluid-type(414px, 1920px, 14px, 22px);
      color: $grey-light;
      line-height: 3.2rem;
      @include media-breakpoint-down(md) {
        line-height: 3rem;
      }
      @include media-breakpoint-down(sm) {
        line-height: 2.6rem;
      }
    }
  }
}
.line {
  width: 100%;
  background-color: $yellow;
  border-radius: 1px;
  margin-top: 20px;
  height: 1px;
  @include media-breakpoint-down(sm) {
    margin-top: 16px;
  }
}
#wagtail-userbar-trigger{
  font-size: 1.6rem!important;
}