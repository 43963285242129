.features-block{
    position: relative;
    margin-bottom: 80px;
    margin-top: 100px;
    @media (max-width: 1300px){
        padding-top: 40px;
    }
    @include media-breakpoint-down(lg) {
        margin-bottom: 20px;
    }
    @include media-breakpoint-down(sm) {
        padding-top: 100px;
    }
    &.airdate\.svg{
        .line {
            background-color: $green;
        }
    }
    &.askell\.svg{
        .line {
            background-color: $askell-light-blue;
            ;
        }
    }
    &.airserve\.svg{
        .line{
            background-color: $pink;
        }
    }
    h2{
        margin-bottom: 30px;
        font-size: calc(30px + (44 - 30) * ((100vw - 414px) / (1920 - 414))) !important;
        font-weight: 700 !important;
        padding-left: 102px;
        @media(min-width: 1920px){
            font-size: 44px;
        } 
        @include media-breakpoint-down(md){
            padding-left: calc(4px + (17 - 4) * ((100vw - 414px) / (1920 - 414)));
            margin-bottom: 30px;
            font-size: 30px;
        }
        @include media-breakpoint-down(sm){
            margin-bottom: 0 !important;
        }
    }
    .feature-block{
        position: relative;
        display: flex;
        justify-content: center;
        min-height: 170px;
        @include media-breakpoint-down(lg) {
            margin: 20px 0px;
        }
        @include media-breakpoint-down(md) {
            margin: 20px 8px 20px 0;
        }
        @include media-breakpoint-down(sm) {
            margin: 0px 8px;
            display: unset;
        }
        .img-container{
            min-width: 105px;
            @include media-breakpoint-up(md){
                position: absolute;
                left: -20px;
                top: -10px;
            }
            @include media-breakpoint-down(md){
                left: 0;
                top: -53px;
            }
            @include media-breakpoint-down(lg) {
                min-width: 80px;
            }
            @include media-breakpoint-down(sm) {
                margin-bottom: 20px;
                left: -20px;
                top: -10px;
            }
            svg{
                max-width: 80px;
                @include media-breakpoint-down(lg) {
                    height: 40px;
                }
                @include media-breakpoint-down(sm) {
                    height: 55px;
                }
                line{
                    stroke: $askell-light-blue;
                }
                g, path, polygon{
                    fill: $green;
                }
            }
        }
    }
    .content {
        padding-left: 90px;
        @include media-breakpoint-down(md){
            padding-left: 0;
        }
        @include media-breakpoint-down(sm){
            max-width: 90vw;
            margin: 0 auto;
        }
        .description {
            @include media-breakpoint-down(sm) {       
                font-size: 1.8rem;
            }
        }
    }
    &.airserve\.svg{
        .feature-block{
            .img-container{
                svg{
                    line{
                        stroke: $askell-light-blue;
                    }
                    g, path, polygon{
                        fill: $pink;
                    }
                }
            }
        }
    }
    &.askell\.svg{
        .feature-block{
            .img-container{
                svg{
                    line{
                        stroke: $askell-light-blue;
                    }
                    g, path, polygon{
                        fill: $askell-light-blue
                    }
                }
            }
        }
    }
}
