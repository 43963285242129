    
.blog-page {
    .hero-block {
        .author {
            .author-img {
                height: 64px;
                width: 64px;
                border-radius: 50%;
                overflow: hidden;
                background-color: $yellow;
                display: flex;
            }
            img {
                max-height: calc(100% - 8px);
                max-width: calc(100% - 8px);
                align-self: flex-end;
                margin: 0 auto;
            }
            .text-container {
                font-size: 16px;
                font-weight: 300;
                line-height: 24px;
                padding-left: 24px;
                height: 64px;
                display: flex;
                flex-direction: column;
                justify-content: center;
                .rich-text {
                    p {
                        font-size: 16px;
                        font-weight: 300;
                        line-height: 24px;
                        margin-bottom: 0px;
                    }
                }
                .date-container {
                    margin-bottom: 0px;
                }
            }
        }
    }
    .header-image-container{
        width: 100%;
        margin-left: auto;
        margin: 60px 0px 160px auto;
        max-height: 820px;
        padding-right: 0;
        position: relative;
        overflow: hidden;
        @include media-breakpoint-down(sm) {
            margin: 50px 0px 40px auto;
            max-height: 400px;
        }
        img {
            height: auto;
            object-fit: cover;
            width: 100%;
        }
    }
    .paragraph-block {
        max-width: 840px;
        padding: 40px 0px 0px;
        @include media-breakpoint-down(sm) {      
            padding: 24px 0px 0px;
        }
    }
}

@include media-breakpoint-down(md){
    .blog-page{
        .line{
            &::after{
                background-color: transparent !important;
            }
        }
    }
}