.ar-title{
    background-color: $grey-d2;
    height: 71px;
    margin-bottom: 0 !important;
    h4{
        @include fluid-type(414px, 1920px, 18px, 24px);
        color: $white !important;
        line-height: calc(24px + (28 - 24) * ((100vw - 414px) / (1920 - 414)));
        @include media-breakpoint-down(xl){
            padding: 20px;
        }
        @include media-breakpoint-down(md){
            padding: 20px;
        }
    }
}
.ar-slider-container{
    margin-bottom: 180px;
    margin-left: -4px;
    position: relative;
    width: calc(100% + 8px);
    .ar-slider{
        position: relative;
        overflow-x: hidden;
        .slide{
            min-width: 100vw;
            padding-bottom: 140px;
            padding-top: 150px;
            scroll-snap-align: start;
            @include media-breakpoint-down(md){
                padding-bottom: 70px;
                padding-top: 35px;
            }
            .ar-card{
                background-color: $white;
                box-shadow: 0 25px 50px 0 rgba(0,0,0,0.15);
                padding: 100px 31% 60px 120px;
                position: relative;
                @include media-breakpoint-between(md, lg){
                    padding: 70px 27% 70px 40px;
                }
                @include media-breakpoint-down(md){
                    display: flex;
                    flex-wrap: wrap;
                    padding: 30px 26px;
                }
                .img-container{
                    right: -240px;
                    top: 50%;
                    transform: translateY(-50%);
                    position: absolute;
                    max-width: 820px;
                    width: calc(500px + (806 - 500) * ((100vw - 992px) / (1920 - 992)));
                    @include media-breakpoint-down(md){
                        display: block;
                        min-width: calc(100% + 52px);
                        margin-bottom: -30px;
                        margin-left: -26px;
                        order: 2;
                        position: relative;
                        right: unset;
                        transform: none;
                        top: unset;
                    }
                    img{
                        height: auto;
                        max-width: 100%;
                        min-width: 100%;
                    }
                }
                .description{
                    .rich-text{
                        max-height: 200px;
                        overflow: hidden;
                    }
                    ul{
                        padding-left: 0;
                        li{
                            color: $grey-d2 !important;
                            font-size: 20px !important;
                            line-height: 30px !important; 
                            list-style: none;
                            margin-bottom: 10px;
                            padding-left: 20px;
                            position: relative;
                            &:before{
                                background-color: $yellow-d1;
                                border-radius: 50%;
                                content: '';
                                height: 6px;
                                left: 0;
                                position: absolute;
                                top: 13px;
                                width: 6px;
                            }
                        }
                    }
                    a{
                        text-decoration: none;
                        &:hover{
                            text-decoration: none;
                        }
                        .link{
                            background-color: $grey-d2;
                            border-radius: 33px;
                            color: $white;
                            font-size: 18px;
                            font-weight: 600;
                            margin-top: 46px;
                            height: 54px;
                            width: 230px;
                            @include media-breakpoint-down(md){
                                margin: 40px auto 25px auto;
                            }
                        }
                    }
                }
            }
        }
    }
    .next-slide, .prev-slide{
        background-color: transparent;
        border: none;
        box-shadow: none;
        position: absolute;
        top: calc(50% - 25px);
        &:active, &:focus{
            box-shadow: none;
            outline: none;
        }
    }
    .next-slide{
        right: 50px;
    }
    .prev-slide{
        left: 50px;
        transform: rotate(180deg);
    }
    .slick-track{
        display: flex !important;
    }
    .slick-arrow{
        background-color: transparent;
        border: none;
        box-shadow: none;
        color: transparent;
        height: 100%;
        position: absolute;
        top: 0;
        width: 100px;
        z-index: 10;
        &:active, &:focus{
            box-shadow: none;
            outline: none;
        }
    }
    .slick-next{
        right: 0;
    }
    .slick-prev{
        left: 0;
    }
    .slick-dots{
        bottom: 38px;
        display: flex;
        justify-content: center;
        padding-left: 0;
        position: absolute;
        width: 100%;
        @include media-breakpoint-down(md){
            bottom: 20px;
        }
        li{
            list-style: none;
            margin-right: 29px;
            &:last-child{
                margin-right: 0;
            }
            &.slick-active{
                button {
                    background-color: $yellow-d1;
                }
            }
            button{
                background-color: $white;
                border: none;
                border-radius: 100%;
                box-shadow: none;
                color: transparent;
                max-height: 15px !important;
                max-width: 15px !important;
                padding: 0 !important;
                outline: none;
                width: 15px;
                -webkit-appearance: none;
                &:active, &:focus{
                    box-shadow: none;
                    outline: none;
                }
                @include media-breakpoint-down(md){
                    max-height: 18px !important;
                    max-width: 18px !important;
                    width: 18px;
                }
            }
        }
    }
}