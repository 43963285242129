.hero-block{
    margin-bottom: 100px;
    @include media-breakpoint-down(sm){
        margin-bottom: 50px;
    }
    &:first-child{
        position: relative;
        .line{
            position: relative;
            @include media-breakpoint-up(md){
                &.after{
                    &::after{
                        content: '';
                        position: absolute;
                        background-color: $white;
                        height: 40px;
                        left: -6px;
                        width: 103%;
                        top: 1px;
                        z-index: 10000;
                    }
                }
                &.z-index-restore {
                    z-index: 0;
                }
            }
        }
    }
    .line-col{
        @include media-breakpoint-down(sm){
            order: 2;
            margin: 0 auto 30px auto;
        }
    }
    .line {
        width: 102%;
        background-color: $yellow;
        border-radius: 1px;
        margin-top: 20px;
        height: 2px;
        @include media-breakpoint-down(sm){
            margin-top: 16px;
        }
    }
    .text-col{
        @include media-breakpoint-between(sm, md){
            z-index: 1;
            position: relative;
        }
    }
    h2 {
        &.mobile-text-center {
            @include media-breakpoint-down(sm) {
                text-align: center;
            }
        }
    }
    .rich-text {
        &.mobile-text-center {
            @include media-breakpoint-down(sm) {
                text-align: center;
            }
        }
        ul {
            padding: 0px;
            list-style: none;
            li {
                font-size: 1.8rem;
                display: inline-block;
                padding-left: 14px;
                padding-right: 14px;
                @include media-breakpoint-down(md) {
                    padding-left: 12px;
                    padding-right: 12px;
                    font-size: 1.6rem;
                }
                @include media-breakpoint-down(sm) {
                    padding-left: 10px;
                    padding-right: 10px;
                    font-size: 1.4rem;
                }
                &:nth-of-type(1) {
                    padding-left: 0px;
                    &:before {
                        content: none;
                    }
                }
                &:before {
                    content: "\2022";
                    color: $yellow;
                    font-weight: bold;
                    display: inline-block; 
                    width: 1.4em;
                    margin-left: -1em;
                }
            }
        }
    }
}

