.blog-image-block {
  position: relative;
  background-color: $white-d1;
  .text-container {
    background-color: $white;
    padding: 50px 100px 100px 100px;
    margin: 150px 0 100px -100px;
    height: 350px;
  }
  .order-1{
    .text-container {
      margin: 150px -100px 100px 0;
    }
  }
  .blog-image-container {
    .header-image {
      margin: 100px 100px 100px 0;
      @include media-breakpoint-down(sm) {
        height: 200px;
      }  
      @media only screen and (min-width : 768px) and (max-width : 1024px) {
        height: 270px;
      }
    }
  }
}

