.images-slider {
    width: calc(80% - 40px);
    margin-left: auto;
    padding: 60px 0px 50px;
    @include media-breakpoint-down(sm) {
        padding: 50px 0px 40px;
        width: calc(88% - 32px);
    }
    @include media-breakpoint-down(xs) {
        width: calc(92% - 12px);
    }
    .slick-active{
        transform: translateX(100vw);
    }
    .slick-dots {
        display: flex;
        justify-content: space-evenly;
        padding: 20px 100px 0 100px;
        max-width: 900px;
        li {
            color: $grey-dark;
            &.slick-active {
                color: $yellow; ;
            }
        }
        @include media-breakpoint-down(sm) {
            padding: 20px 75px 0 75px;
        }
    }
    &.visible {
        .slick-active {
            transform: translateX(0);
            transition: transform 1s ease-in-out;
            @for $i from 4 through 8 {
                &:nth-of-type(#{$i}) {
                    transition-delay: 200ms * ($i - 4);
                }
            }
        }
    }
    button {
        display: none!important;
    }
    .slick-list {
        .slick-slide {
            margin: 0px 40px 0px 0px;
            max-width: 440px;
            padding: 20px;
            background-color: $white-d1;
            border-radius: 2px;
            @include media-breakpoint-down(sm) {
                max-width: 300px;
            }
            @include media-breakpoint-down(xs) {
                max-width: 250px;
                margin: 0px 20px 0px 0px;
            }
            .slide {
                img {
                    min-width: 100%;
                    max-height: 80vh;
                }
            }
        }
    }
    &.landscape {
        .slick-slide {
            max-width: 660px;
            @include media-breakpoint-down(sm) {
                max-width: 600px;
            }
            @include media-breakpoint-down(xs) {
                max-width: 460px;
            }
        }
    }
}
.text-container{
    .rich-text{
        p{
            color: $white;
            font-size: 22px;
            line-height: 30px;
        }
        ul{
            margin-top: 40px;
            padding-left: 30px;
            li{
                list-style-type: none;
                position: relative;
                color: $white;
                font-size: 20px;
                line-height: 28px;
                margin-bottom: 12px;
                &:before{
                    content: '';
                    position: absolute;
                    width: 11px;
                    height: 1px;
                    background-color: $yellow;
                    left: -24px;
                    top: 16px;
                }
            }
        }
    }
}