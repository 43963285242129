.portfolio-page {
    padding-bottom: 100px;
    .hero-block{
        margin-bottom: 150px;
    }
    .recruitment-block {
        padding: 120px 0px 0px;
        .text-container {
            p {
                max-width: unset;
            }
        }
    }
    a {
      &:hover {
          text-decoration: none;
      }  
    }
    .caption {
        margin-bottom: 140px;
        @include media-breakpoint-down(md) {
            margin-bottom: 100px;
        }
        @include media-breakpoint-down(sm) {
            margin-bottom: 60px;
        }
    }
    .animated-img {
        position: relative;
        .snura {
            top: -500px;
            position: absolute;
            width: 335px;
            right: 238px;
            @include media-breakpoint-down(xl) {
                width: 250px;
                right: 214px;
                top: -440px;
            }
            @include media-breakpoint-down(lg) {
                width: 200px;
                right: 135px;
                top: -433px;
            }
        }
        .pera {
            position: absolute;
            top: -450px;
            right: 120px;
            width: 250px;
            -webkit-animation: rotating 4s .2s ease-in-out;
            -moz-animation: rotating 4s .2s ease-in-out;
            -ms-animation: rotating 4s .2s ease-in-out;
            -o-animation: rotating 4s .2s ease-in-out;
            animation: rotating 4s .2s ease-in-out;
            transform-origin: 50% 0%;
            @include media-breakpoint-down(xl) {
                top: -402px;
                width: 200px;
            }
            @include media-breakpoint-down(lg) {
                width: 150px;
                right: 65px;
            }
        }
        @include media-breakpoint-down(md) {
            display: none;
        }
    }
    .portfolio-item-block {
        margin-top: 0px;
        width: calc(50% - 20px);
        position: relative;
        transform: translateY(80px) scale(.9);
        opacity: .6;
        transition: all .3s ease-in-out;
        @include media-breakpoint-down(md) {
            margin-top: 120px;
            margin-bottom: 80px;
            width: calc(100% - 160px);
        }
        @include media-breakpoint-down(sm) {
            width: calc(100% - 80px);
            margin-top: 80px;
        }
        @include media-breakpoint-down(xs) {
            width: calc(100% - 20px);
        }
        &:after {
            content: "";
            position: absolute;
            top: -60px;
            left:0px;
            width: 100%;
            background-color: #F4F4F4;
            height: 100%;
            z-index: 0;
            @media (min-width: 1920px) {
                left: calc(0px - ((100vw - 1920px) / 2));
                width: calc(100% + ((100vw - 1920px) / 2));
            }
            @include media-breakpoint-down(md) {
                top: -40px;
            }
        }
        .page-info {
            position: relative;
            z-index: 1;
            padding: 0px 50px;
            width: 100%;
            max-width: 800px;
            margin-left: auto;
            @include media-breakpoint-down(md) {
                padding: 0px 40px;
                max-width: 780px;
            }
            @include media-breakpoint-down(xs) {
                padding-left: 0px;
            }
            a {
                color: $grey-dark;
                .text {
                    margin-bottom: 20px;
                    padding-left: 40px;
                    @include media-breakpoint-down(xs) {
                        padding-left: 40px;
                    }
                    h3 {
                        font-size: 2.8rem;
                        line-height: 3.2rem;
                        font-weight: 900;
                    }
                    p {
                        font-size: 1.4rem;
                        font-weight: 300;
                        letter-spacing: 2px;
                        line-height: 1.9rem;
                        text-transform: uppercase;
                    }
                }
            }
        }
        &.right-align {
            margin-left: auto;
            margin-top: -370px;
            @media (min-width: 1600px) {
                margin-top: -470px;
            }
            @include media-breakpoint-down(lg) {
                margin-right: 0px;
                margin-top: -270px;
            }
            @include media-breakpoint-down(md) {
                margin-top: 120px;
                margin-bottom: 80px;
            }
            @include media-breakpoint-down(sm) {
                margin-top: 80px;
            }
            &:after {
                left: 0px
            }
            .page-info {
                margin-left: unset;
                @include media-breakpoint-down(xs) {
                    padding-right: 0px;
                    padding-left: 40px;
                }
                a {
                    .text {
                        @include media-breakpoint-down(xs) {
                            padding-left: 0px;
                            padding-right: 40px;
                        }
                    }
                }
            }
        }
        &.visible {
            transform: translateY(0px) scale(1);
            opacity: 1; 
        }
    }
}



@keyframes rotating
	{
    0%
		{
		transform: rotate(0deg);
		-o-transform: rotate(0deg);
		-ms-transform: rotate(0deg);
		-moz-transform: rotate(0deg);
		-webkit-transform: rotate(0deg);
		}
	7%
		{
		transform: rotate(-20deg);
		-o-transform: rotate(-20deg);
		-ms-transform: rotate(-20deg);
		-moz-transform: rotate(-20deg);
		-webkit-transform: rotate(-20deg);
		}
	16%
		{
		transform: rotate(18deg);
		-o-transform: rotate(18deg);
		-ms-transform: rotate(18deg);
		-moz-transform: rotate(18deg);
		-webkit-transform: rotate(18deg);
        }
    25%
        {
		transform: rotate(-16deg);
		-o-transform: rotate(-16deg);
		-ms-transform: rotate(-16deg);
		-moz-transform: rotate(-16deg);
		-webkit-transform: rotate(-16deg);
        }
    35%
        {
        transform: rotate(14deg);
        -o-transform: rotate(14deg);
        -ms-transform: rotate(14deg);
        -moz-transform: rotate(14deg);
        -webkit-transform: rotate(14deg);
        }
    45%
        {
		transform: rotate(-12deg);
		-o-transform: rotate(-12deg);
		-ms-transform: rotate(-12deg);
		-moz-transform: rotate(-12deg);
		-webkit-transform: rotate(-12deg);
        }
    55%
        {
		transform: rotate(10deg);
		-o-transform: rotate(10deg);
		-ms-transform: rotate(10deg);
		-moz-transform: rotate(10deg);
		-webkit-transform: rotate(10deg);
        }
    65%
        {
		transform: rotate(-8deg);
		-o-transform: rotate(-8deg);
		-ms-transform: rotate(-8deg);
		-moz-transform: rotate(-8deg);
		-webkit-transform: rotate(-8deg);
        }
    78%
        {
		transform: rotate(6deg);
		-o-transform: rotate(6deg);
		-ms-transform: rotate(6deg);
		-moz-transform: rotate(6deg);
		-webkit-transform: rotate(6deg);
        }
    90%
        {
		transform: rotate(-4deg);
		-o-transform: rotate(-4deg);
		-ms-transform: rotate(-4deg);
		-moz-transform: rotate(-4deg);
		-webkit-transform: rotate(-4deg);
        }
    100%
        {
		transform: rotate(0deg);
		-o-transform: rotate(0deg);
		-ms-transform: rotate(0deg);
		-moz-transform: rotate(0deg);
		-webkit-transform: rotate(0deg);
		}
	}
