.blog-page-block {
    .blog-container {
        margin-bottom: 180px;

        &:nth-child(even){
            .header-image-container{
                order: 2;
                margin-left: 0 !important;
            }

            .block-container{
                order: 1;
                margin-right: -6.25%;
            }
        }

        .header-image-container{
            margin-bottom: 0;
            margin-right: -6.25%;

            img{
                height: auto;
                object-fit: cover;
                width: 100%;
            }
        }
        .block-container {
            height: 100%;
            color: $grey-dark;
            background-color: $white;
            -webkit-box-shadow: 0px 30px 70px 0px rgba(0, 0, 0, 0.15);
            -moz-box-shadow: 0px 30px 70px 0px rgba(0, 0, 0, 0.15);
            box-shadow: 0 25px 100px 0 rgba(0, 0, 0, 0.15);
            padding: 60px;
            position: relative;
            .text-container {
                .plus {
                    position: absolute;
                    background: $grey;
                    border-radius: 50%;
                    height: 96px;
                    width: 96px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    left: 50%;
                    bottom: 0px;
                    transform: translate(-50%, 50%);
                    border: none;
                    img {
                        z-index: 2;
                    }
                    &:after {
                        content: "";
                        position: absolute;
                        left: 50%;
                        top: 50%;
                        width: 0px;
                        height: 0px;
                        transform: translate(-50%, -50%);
                        background-color: $yellow;
                        border-radius: 50%;
                        transition: all .2s ease-out;
                    }
                    &:hover {
                        &:after {
                            width: 102%;
                            height: 102%;
                            transition: all .2s ease-out;
                        }
                    }
                    &:focus {
                        box-shadow: none;
                    }
                }
            }
        }
    }
}

@include media-breakpoint-down(md){
    .blog-page-block{
        .blog-container{
            .image-col{
                margin-right: 0 !important;
            }

            .block-col{
                margin-top: -20px;

                .text-container{
                    .excerpt{
                        margin-top: 20px;

                        p{
                            font-size: 18px !important;
                            line-height: 26px;
                        }
                    }
                }
            }
        }
    }
}

@include media-breakpoint-down(sm){
    .blog-page-block{
        .blog-container{
            margin-bottom: 100px;

            .block-container{
                padding: 30px;
                margin-left: 0;

                .text-continer{
                    .excerpt{
                        p{
                            font-size: 18px !important;
                            line-height: 26px;
                        }
                    }

                    .plus{
                        width: 80px;
                        height: 80px;
                    }
                }
            }
        }
    }
}