.quote-container{
  
    @include media-breakpoint-down(xl) {
      top: 1vw;
    }

    @include media-breakpoint-down(md) {
      top: 1vw;
    }
    @include media-breakpoint-down(sm) {
      left: 0vw;
    }
    @include media-breakpoint-down(xs) {
      left: 1vw;
      height: 14vw;
      top: 0vw;
    }
  
  blockquote {
    position: relative;
    margin-bottom: 90px;
  
    &:after {
      content: "";
      border-left: 2px solid $yellow;
      height: 110%;
      position: absolute;
      top: 0%;
      left: -2vw;
      width: unset !important;
    }
  }
}