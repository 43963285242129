.recruitment-block {
    padding: 40px 0px 120px;
    &.eight{
        position: absolute;
        background-color: transparent !important;
        bottom: 0;
        width: 100%;
        @include media-breakpoint-down(md){
            position: relative;
            background-color: $white-d1 !important;
        }
    }
    &.grey-background {
        background-color: $white-d1;
    }
    .text-container {
        margin: 0 auto;
        width: calc(100vw - ((100vw - 200px) * 0.4) - 80px);
        @media (min-width: 1440px) {
            max-width: 1340px;
            width: calc(100vw - 580px);
        }
        @include media-breakpoint-down(lg) {
            max-width: calc(100vw - 80px);
            width: calc(100vw - (((100vw - 200px) * 0.4) - 320px) - 110px);
        }
        @include media-breakpoint-down(sm) {
            width: calc(100vw - ((100vw - 64px) * 0.24) - 60px);
        }
        @include media-breakpoint-down(xs) {
            max-width: calc(100vw - 120px);
        }
        @media (max-width: 400px) {
            width: 100vw;
        }
        p {
            max-width: 450px;
        }
    }
}