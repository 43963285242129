
  .paragraph-container {
    &.link {
      padding: 0px;
      width: fit-content;
      width: -moz-fit-content;
      margin: 0 auto;
    }
    h2{
      margin-bottom: 20px;
    }
    .paragraph-title {
      &.checkmark{
        margin-bottom: 100px;
        p, h3, h4{
          padding-left: 60px;
          @include media-breakpoint-down(sm){
            padding-left: 42px;
          }
        }
        h2,h3,h4{
          position: relative;
          &::before{
            content: url("data:image/svg+xml;base64, PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz4KPHN2ZyB3aWR0aD0iMjZweCIgaGVpZ2h0PSIyMHB4IiB2aWV3Qm94PSIwIDAgMjYgMjAiIHZlcnNpb249IjEuMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayI+CiAgICA8IS0tIEdlbmVyYXRvcjogc2tldGNodG9vbCA2My4xICgxMDEwMTApIC0gaHR0cHM6Ly9za2V0Y2guY29tIC0tPgogICAgPHRpdGxlPjZCRkE4QUMzLUNFMTEtNDA4QS1COTU0LTJCODQxRjFDRUZGRDwvdGl0bGU+CiAgICA8ZGVzYz5DcmVhdGVkIHdpdGggc2tldGNodG9vbC48L2Rlc2M+CiAgICA8ZyBpZD0iMTYtZ3JpZCIgc3Ryb2tlPSJub25lIiBzdHJva2Utd2lkdGg9IjEiIGZpbGw9Im5vbmUiIGZpbGwtcnVsZT0iZXZlbm9kZCI+CiAgICAgICAgPGcgaWQ9IsOhcnNrw71yc2x1ci1Nb2JpbGUtMTZjb2wiIHRyYW5zZm9ybT0idHJhbnNsYXRlKC00MS4wMDAwMDAsIC0yMTU2LjAwMDAwMCkiIGZpbGw9IiNGOEQxMUMiPgogICAgICAgICAgICA8cG9seWdvbiBpZD0iUGF0aC0yMiIgcG9pbnRzPSI0MSAyMTY2LjkzMTggNDMuODEyNTE0NyAyMTY0LjA0MTk2IDUwLjE3MzYzNCAyMTcwLjU3Nzk3IDY0LjM2MTUyNDEgMjE1NiA2NyAyMTU4LjcxMTAyIDUwLjE3MzYzNCAyMTc2Ij48L3BvbHlnb24+CiAgICAgICAgPC9nPgogICAgPC9nPgo8L3N2Zz4=");
            height: 20px;
            position: absolute;
            left: 0;
            top: 2px;
            width: 26px;
          }
        }
      }
      .rich-text {
        p {
          margin-bottom: 24px;
        }
        a {
          border-bottom: 1px solid $yellow;
          color: $grey-dark;
          text-decoration: none;
          padding-bottom: 2px;
          font-weight: 500;
        }
      }
    }
  }
