.contact-block{
    .image-container{
        position: absolute;
        right: 0;
        left: calc(50% - 50vw);
        img{
            max-width: 1000px;
        }
    }
    .text-container{
        background-color: $yellow;
        padding: 160px 140px;
        p{
            color: $grey_d2;
        }
    }
}