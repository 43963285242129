.image-block {
    margin: 0 auto;
    padding: 60px 0px 50px;
    @include media-breakpoint-down(sm) {
      padding: 50px 0px 40px;
    }
    .image-container {
        img {
            min-width: 100%;
        }
    }
    .text-container {
        p {
            font-size: 18px;
            @include media-breakpoint-down(md) {
                font-size: 16px;
            }
            @include media-breakpoint-down(sm) {
                font-size: 14px;
            }
        }
    }
}