
  .embed-block{
    margin: 0 auto;
    text-align: center;
    @include media-breakpoint-down(md){
      margin: 0;
      width: 100%;
    }
    .responsive-object{
      position: relative;
      width: 100%;
      iframe{
        top: 0;
        left: 0;
        position: absolute;
        height: 100%;
        width: 100%;
      }
    }
  }

