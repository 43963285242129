footer {
    background-color: $grey-d2;
    color: $white;
    &.show {
        .top-container, .middle-container, .bottom-container {
            transform: translateX(0%)!important;
            transition: .5s transform ease-in-out;
        }
    }
    h3 {
        color: $white;
    }
    div.footer-container{
        padding: 108px 160px 120px;
        display: flex;
        flex-direction: column;
        overflow-x: hidden;
        @include media-breakpoint-down(md) {
            padding: 80px 120px 100px;
        }
        @include media-breakpoint-down(sm) {
            padding: 60px 80px 70px;
        }
        @include media-breakpoint-down(xs) {
            padding: 40px 30px 50px;
        }
        .top-container, .middle-container, .bottom-container {
            transition: .5s transform ease-in-out;
        }
        div.top-container{
            transform: translateX(-100%);
            .name {
                svg {
                    width: 145px;
                    path:nth-child(1) {
                        fill: $white;
                    }
                }
            }
        }
        .middle-container {
            transform: translateX(100%);
            display: flex;
            justify-content: flex-end;
            margin: 20px 0px 40px;
            @include media-breakpoint-down(lg) {
                flex-direction: column;
            }
            .info {
                display: flex;
                flex-direction: column;
                text-align: right;
                padding: 0px 16px;
                color: $white;
                &:nth-of-type(1) {
                    padding-left: 0px;
                }
                &:nth-of-type(3) {
                    padding-right: 0px;
                }
                &:hover {
                    text-decoration: none;
                }
                p {
                    color: $white!important;
                    font-size: 1.8rem;
                    @include media-breakpoint-down(sm) {
                        font-size: 1.6rem;
                    }
                }
                @include media-breakpoint-down(lg) {
                    padding: 24px 0px;
                    &:nth-of-type(1) {
                        padding-top: 0px;
                    }
                    &:nth-of-type(3) {
                        padding-bottom: 0px;
                    }
                }
            }
        }
        div.bottom-container{
            transform: translateX(-100%);
            display: flex;
            .social {
                a{
                    color: $white!important;
                    @extend %subtext;
                    padding: 0px 16px;
                    &:nth-child(1) {
                        padding-left: 0px;
                    } 
                    &:nth-child(3) {
                        padding-right: 0px;
                    }
                    &:hover {
                        text-decoration: none;
                    } 
                    @include media-breakpoint-down(md) {
                        padding: 0px 8px;
                    }
                }
            }
        }
    }
}
