.target-groups{
    margin-bottom: 120px;
    .list-container{
        background-color: $white;
        box-shadow: 0 25px 50px 0 rgba(0,0,0,0.15);
        padding: calc(30px + (60 - 30) * ((100vw - 1200px) / (1920 - 1200)));
        position: relative;
        &::after{
            background-color: $yellow-d1;
            bottom: -34px;
            content: '';
            height: 34px;
            left: 3%;
            position: absolute;
            width: 94%;
        }
        @media (min-width: 1920px){
            padding: 60px;
        }
        @media (max-width: 1200px){
            padding: 30px;
        }
        @media (max-width: 768px){
            padding: 20px;
            &::after {
                bottom: -20px;
                height: 20px;
            }
        }
        .group-card{
            padding: 12px;
            padding-left: 32px !important;
            position: relative;
            &:first-child{
                padding-left: 12px !important;
            }
            &::after{
                content: '';
                background-color: $grey-l2;
                height: 100%;
                position: absolute;
                right: 0;
                top: 0;
                width: 1px;
            }
            @include media-breakpoint-down(md){
                padding: 20px 0 20px 0 !important;
                &::after{
                    content: none;
                }
                &:first-child{
                    padding-left: 0 !important;
                }
            }
            &:last-child{
                &::after{
                    content: none;
                }
            }
            h4{
                margin-bottom: 30px;
            }
            ul{
                padding-left: 0;
                li{
                    color: $grey !important;
                    list-style: none;
                    margin-bottom: 8px;
                    padding-left: 20px;
                    position: relative;
                    &:before{
                        background-color: $yellow-d1;
                        border-radius: 50%;
                        content: '';
                        height: 6px;
                        left: 0;
                        position: absolute;
                        top: 13px;
                        width: 6px;
                    }
                    @include media-breakpoint-down(lg){
                        font-size: 18px;
                    }
                }
            }
        }
    }
}